export const timeZoneEn = [
  {
    label: 'East 1nd District',
    value: 'Etc/GMT-1'
  },
  {
    label: 'East 2nd District',
    value: 'Etc/GMT-2'
  },
  {
    label: 'East 3nd District',
    value: 'Etc/GMT-3'
  },
  {
    label: 'East 4nd District',
    value: 'Etc/GMT-4'
  },
  {
    label: 'East 5nd District',
    value: 'Etc/GMT-5'
  },
  {
    label: 'East 6nd District',
    value: 'Etc/GMT-6'
  },
  {
    label: 'East 7nd District',
    value: 'Etc/GMT-7'
  },
  {
    label: 'East 8th District',
    value: 'Etc/GMT-8'
  },
  {
    label: 'East 9nd District',
    value: 'Etc/GMT-9'
  },
  {
    label: 'East 10nd District',
    value: 'Etc/GMT-10'
  },
  {
    label: 'East 11nd District',
    value: 'Etc/GMT-11'
  },
  {
    label: 'East 12nd District',
    value: 'Etc/GMT-12'
  },
  {
    label: 'West 1nd District',
    value: 'Etc/GMT+1'
  },
  {
    label: 'West 2nd District',
    value: 'Etc/GMT+2'
  },
  {
    label: 'West 3rd District',
    value: 'Etc/GMT+3'
  },
  {
    label: 'West 4nd District',
    value: 'Etc/GMT+4'
  },
  {
    label: 'West 5nd District',
    value: 'Etc/GMT+5'
  },
  {
    label: 'West 6nd District',
    value: 'Etc/GMT+6'
  },
  {
    label: 'West 7nd District',
    value: 'Etc/GMT+7'
  },
  {
    label: 'West 8nd District',
    value: 'Etc/GMT+8'
  },
  {
    label: 'West 9nd District',
    value: 'Etc/GMT+9'
  },
  {
    label: 'West 10nd District',
    value: 'Etc/GMT+10'
  },
  {
    label: 'West 11nd District',
    value: 'Etc/GMT+11'
  },
  {
    label: 'West 12nd District',
    value: 'Etc/GMT+12'
  }
]
