<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="600px"
    :title="isDelete ? $t('details') : $t('deviceList.scheduledRestart')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <div class="wrap">
      <!-- <div class="left">
        <div class="flex-align-center">
          <el-input
            style="width:350px;padding-right:12px"
            :placeholder="$t('deviceList.filter')"
            v-model="filterText"
          />
          <div>已选中：{{ selectCount }}</div>
        </div>
        <el-tree
          ref="tree"
          :data="treeData"
          show-checkbox
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          :props="defaultProps"
          :filter-node-method="filterNode"
          @check-change="handleCheckChange"
        />
      </div> -->
      <div class="right">
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          :disabled="isDelete"
        >
          <el-form-item :label="$t('deviceList.executionMode')" prop="executionMode">
            <el-select
              @change="
                form.executionDate = form.executionMode == 3 || form.executionMode == 0 ? null : []
              "
              v-model="form.executionMode"
              :placeholder="$t('placeholder')"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.executionMode == 1"
            :label="$t('deviceList.executionDate')"
            prop="executionDate"
          >
            <el-checkbox-group v-model="form.executionDate">
              <el-checkbox v-for="item in week" :label="item.value" :key="item.value">{{
                item.label
              }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-if="form.executionMode == 2"
            :label="$t('deviceList.executionDate')"
            prop="executionDate"
          >
            <el-select
              multiple
              collapse-tags
              v-model="form.executionDate"
              :placeholder="$t('placeholder')"
            >
              <el-option v-for="day in 31" :key="day" :label="day" :value="String(day)" />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.executionMode == 3"
            :label="$t('deviceList.executionDate')"
            prop="executionDate"
          >
            <el-date-picker
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="form.executionDate"
              type="date"
              :placeholder="$t('placeholder')"
            />
          </el-form-item>
          <el-form-item :label="$t('deviceList.executionTime')" prop="startupTime">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <!-- <el-table-column align="center" type="selection" width="50" /> -->
              <el-table-column label="" width="50">
                <template slot-scope="{ row }">
                  <el-checkbox v-model="row.checked" @click="row.checked = !row.checked" />
                </template>
              </el-table-column>
              <el-table-column
                prop="startupTime"
                :label="$t('deviceList.restartTime')"
                show-overflow-tooltip
                align="center"
              >
                <template slot-scope="{ row }"
                  ><el-time-picker
                    style="width:140px"
                    v-model="row.startupTime"
                    value-format="HH:mm:ss"
                    @change="
                      val => {
                        row.checked = !!val
                      }
                    "
                    :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59'
                    }"
                    :placeholder="$t('placeholder')"
                /></template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="!isDelete">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm()">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['groupIds'],
  props: {
    treeData: {
      type: Array,
      default: () => []
    },
    // 选中的设备数据 sn 列表
    multipleSelection: {
      type: Array,
      default: () => []
    },
    //是否删除定时任务查看详情
    isDelete: {
      type: Boolean,
      default: false
    }
  },
  components: {},

  data() {
    const rules = {
      executionMode: [{ required: true, message: this.$t('placeholder'), trigger: 'change' }],
      executionDate: [{ required: true, message: this.$t('placeholder'), trigger: 'change' }]
    }

    return {
      rules,
      form: {
        executionDate: null
      },
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },

      options: [
        {
          label: this.$t('deviceList.everyDay'),
          value: '0'
        },
        {
          label: this.$t('deviceList.weekly'),
          value: '1'
        },
        {
          label: this.$t('deviceList.everyMonth'),
          value: '2'
        },
        {
          label: this.$t('deviceList.customize'),
          value: '3'
        }
      ],
      week: [
        {
          label: this.$t('deviceList.mon'),
          value: '1'
        },
        {
          label: this.$t('deviceList.tue'),
          value: '2'
        },
        {
          label: this.$t('deviceList.wed'),
          value: '3'
        },
        {
          label: this.$t('deviceList.thu'),
          value: '4'
        },
        {
          label: this.$t('deviceList.fri'),
          value: '5'
        },
        {
          label: this.$t('deviceList.sat'),
          value: '6'
        },
        {
          label: this.$t('deviceList.sun'),
          value: '7'
        }
      ],
      tableData: [
        {
          startupTime: '',
          checked: false
        },
        {
          startupTime: '',
          checked: false
        },
        {
          startupTime: '',
          checked: false
        },
        {
          startupTime: '',
          checked: false
        }
      ],
      selectCount: 0,
      startupTimeList: []
    }
  },
  created() {
    this.init()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    //分类树 节点选中状态发生变化时的回调
    handleCheckChange(data, checked, indeterminate) {
      this.selectCount = this.$refs.tree && this.$refs.tree.getCheckedNodes().length
    },
    handleTime(val, row) {
      this.$refs.multipleTable.toggleRowSelection(row, !!val)
    },
    handleSelectionChange(val) {
      // this.startupTimeList = []
      // let startupTimeList = []
      // val.forEach(item => {
      //   if (item.startupTime) {
      //     startupTimeList.push(item.startupTime)
      //   }
      // })
      // this.startupTimeList = startupTimeList
      // console.log('startupTimeList', startupTimeList)
    },
    async init() {
      try {
        if (this.currentItem?.sn) {
          let { result } = await this.$api.deviceManagement.getRestStartDetail({
            sn: this.currentItem.sn
          })
          if (result && result[0]) {
            // 回显
            this.form = {
              ...result[0],
              executionMode: String(result[0].executionMode),
              executionDate:
                result[0].executionMode == 3
                  ? JSON.parse(result[0].executionDate)[0]
                  : JSON.parse(result[0].executionDate)
            }
            ;(result[0].startupTime.split(',') || []).forEach((startuTime, i) => {
              if (startuTime) {
                this.tableData[i].startupTime = startuTime
                this.tableData[i].checked = true
              }
            })
          }
          console.log(result)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async submitForm() {
      this.$refs.ruleForm.validate(async valid => {
        console.log(this.startupTimeList, valid)
        let startupTimeList = []
        // 时间必选
        startupTimeList = this.tableData.filter(item => item.checked && item.startupTime)
        if (startupTimeList.length == 0) {
          this.$message.warning(this.$t('deviceList.executionTimeTip'))
          return
        }
        if (valid) {
          // if (this.isDelete) {
          //   // 删除提示
          //   await this.$confirm(this.$t('deviceList.confirmTask'), this.$t('tips'), {
          //     confirmButtonText: this.$t('confirm'),
          //     cancelButtonText: this.$t('cancel')
          //   })
          // }
          this.loading = true
          let multipleSelection =
            this.multipleSelection.length == 0 ? [this.currentItem.sn] : this.multipleSelection

          let detailsList = multipleSelection.map(sn => {
            let executionDate = this.form.executionDate
            if (this.form?.executionDate && typeof this.form?.executionDate == 'string') {
              executionDate = [this.form.executionDate]
            }

            return {
              ...this.form,
              startupTime: startupTimeList.map(({ startupTime }) => startupTime).join(','),
              executionDate: JSON.stringify(executionDate),
              sn
            }
          })
          let params = {
            snList: multipleSelection,
            detailsList
          }

          try {
            const res = await this.$api.deviceManagement.restStart(params)
            this.$message.success(this.$t('deviceModal.settingSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  padding: 20px;
  .left,
  .right {
    flex: 1;
    padding: 10px;
  }
}
</style>
