export const timeZone = [
  {
    label: '东一区',
    value: 'Etc/GMT-1'
  },
  {
    label: '东二区',
    value: 'Etc/GMT-2'
  },
  {
    label: '东三区',
    value: 'Etc/GMT-3'
  },
  {
    label: '东四区',
    value: 'Etc/GMT-4'
  },
  {
    label: '东五区',
    value: 'Etc/GMT-5'
  },
  {
    label: '东六区',
    value: 'Etc/GMT-6'
  },
  {
    label: '东七区',
    value: 'Etc/GMT-7'
  },
  {
    label: '东八区',
    value: 'Etc/GMT-8'
  },
  {
    label: '东九区',
    value: 'Etc/GMT-9'
  },
  {
    label: '东十区',
    value: 'Etc/GMT-10'
  },
  {
    label: '东十一区',
    value: 'Etc/GMT-11'
  },
  {
    label: '东十二区',
    value: 'Etc/GMT-12'
  },
  {
    label: '西一区',
    value: 'Etc/GMT+1'
  },
  {
    label: '西二区',
    value: 'Etc/GMT+2'
  },
  {
    label: '西三区',
    value: 'Etc/GMT+3'
  },
  {
    label: '西四区',
    value: 'Etc/GMT+4'
  },
  {
    label: '西五区',
    value: 'Etc/GMT+5'
  },
  {
    label: '西六区',
    value: 'Etc/GMT+6'
  },
  {
    label: '西七区',
    value: 'Etc/GMT+7'
  },
  {
    label: '西八区',
    value: 'Etc/GMT+8'
  },
  {
    label: '西九区',
    value: 'Etc/GMT+9'
  },
  {
    label: '西十区',
    value: 'Etc/GMT+10'
  },
  {
    label: '西十一区',
    value: 'Etc/GMT+11'
  },
  {
    label: '西十二区',
    value: 'Etc/GMT+12'
  }
]
